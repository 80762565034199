<!-- routify:options bundle=true -->
<script>
  export let scoped;

  import {
    blankCustomerDetails,
    fetchJSON,
    loadOrInitialiseSession,
    getCountryCode,
    getPrice,
    getAccessibleStages,
  } from "../../utils.js";
  import { loadingCount } from "../../stores.js";
  import { getServerState } from "../../utils";

  import Error from "../../components/Error.svelte";
  import Header from "../../components/Header.svelte";
  import Steps from "../../components/Steps.svelte";

  import { register, locale, init } from "svelte-i18n";

  register("en", () => import("../../../public/lang/en.json"));
  locale.set("en");
  localStorage.setItem("enrol-app-locale", "en");
  init({
    fallbackLocale: "en",
    initialLocale: "en",
  });

  const { loading: parentLoading } = scoped;

  let language;
  if (localStorage.getItem("enrol-app-locale")) {
    language = localStorage.getItem("enrol-app-locale");
  } else {
    language = "en";
  }

  let maintenance_message;
  let enabled;
  getServerState(language).then((res) => {
    if (res.enabled_maintenance_page) {
      maintenance_message = res.maintenance_page;
      enabled = res.enabled_maintenance_page;
    }
  });

  const session = loadOrInitialiseSession("druid", {
    selectedPriceID: null,
    customerDetails: blankCustomerDetails(),
    selectedCountryCode: null,
  });

  let prices, props;
  $: {
    const price = prices && getPrice(prices, $session.selectedPriceID);

    props = {
      ...scoped,
      price,
      prices,
      session,
      countryCode: getCountryCode({
        detectedCountryCode: scoped.detectedCountryCode,
        selectedCountryCode: $session.selectedCountryCode,
      }),
      accessibleStages: getAccessibleStages($session),
      countryCodeIsSelected: $session.selectedCountryCode !== null,
    };
  }

  const loading = Promise.all([
    parentLoading,
    fetchJSON("/api/v1/shops/druid/prices").then(({ data }) => {
      prices = data;
    }),
  ]);

  loadingCount.update((n) => n + 1);
  loading.finally(() => loadingCount.update((n) => n - 1));
</script>

{#if !enabled}
  <Header title="Druid Grade Enrolment">
    <Steps />
  </Header>
{/if}

{#await loading}
  <!-- Loading -->
{:then}
  <main>
    {#if enabled}
      {@html maintenance_message}
    {:else}
      <slot scoped={props} />
    {/if}
  </main>
{:catch error}
  <Error {error} />
{/await}
