<script>
  import { updateSession, getPrice, getRegion } from "../../utils.js";
  import { goto, url } from "@roxi/routify";

  import CountryName from "../../components/CountryName.svelte";
  import CurrencyConverter from "../../components/CurrencyConverter.svelte";
  import PriceDisplay from "../../components/PriceDisplay.svelte";
  import ProductSelect from "../../components/ProductSelect.svelte";
  import VariantSelect from "../../components/VariantSelect.svelte";
  import ContactUsForm from "../../components/ContactUsForm.svelte";

  export let scoped;
  const {
    countries,
    symbols,
    rates,
    prices,
    session,
    countryCode,
    countryCodeIsSelected,
  } = scoped;

  let isOpen = false;
  const region = getRegion(countries, countryCode);
  const regionalPrices = prices.filter((price) => price.region.code === region);
  const initialPrice = getPrice(regionalPrices, $session.selectedPriceID);

  let product;
  product = initialPrice ? initialPrice.variant.product : null;

  let variant;
  variant = initialPrice ? initialPrice.variant : null;

  let price;
  $: price =
    product && variant
      ? regionalPrices.find((price) => price.variant.id === variant.id)
      : null;

  let toggleCurrencyConverter;

  const productDetails = {
    druid_text: {
      description: "Printed",
      blurb: `
        Receive the full Druid Course in printed booklet format, including
        supplementary materials posted monthly over 12 months.  Also you’ll
        receive by email the monthly magazine Touchstone, have access to
        additional private web forums for this grade, and a personal mentor.
      `,
      image: false,
    },
  };

  function formSubmit() {
    updateSession(session, {
      selectedPriceID: price.id,
      selectedPriceRegionCode: price.region.code,
    });
    $goto("./details");
  }
</script>

<form on:submit|preventDefault={formSubmit}>
  <section>
    <h2>Greetings!</h2>
    <p class="space-under">
      If you have received your invitation letter to progress on to the next
      grade, here you may register your enrolment for the Druid course:
    </p>

    <ProductSelect
      prices={regionalPrices}
      details={productDetails}
      bind:product
    />
  </section>

  <VariantSelect prices={regionalPrices} bind:variant />

  <section class="links">
    <div class="change-location">
      <p>
        All our prices in GBP based on your
        {#if countryCodeIsSelected}
          selected
        {:else}
          detected
        {/if}
        location in: <CountryName {countries} {countryCode} />
      </p>
      <a href={$url("./country")}>&gt; Change my location</a>
    </div>

    <div class="course-companion-link">
      <p>
        Registering as a Course Companion? - Please do not enrol here, but
        follow this link instead to register yourself:
      </p>
      <a
        href="https://store.druidry.org/collections/obod-course-extras/products/druid-course-additional-member-reg-fee"
        >&gt; Course Companion Registration</a
      >
    </div>

    {#if price}
      <div class="currency-converter">
        <p>Click here to see this price in another currency.</p>
        <button on:click|preventDefault={toggleCurrencyConverter}
          >Currency Converter</button
        >
      </div>
    {/if}

    <button
      class="open"
      on:click|preventDefault={() => {
        isOpen = true;
      }}>> Feedback / Problems? Contact us</button
    >

    <br /><br /><br /><br />
  </section>

  <CurrencyConverter
    bind:toggle={toggleCurrencyConverter}
    {countries}
    {symbols}
    {rates}
    {price}
    {countryCode}
  />

  <ContactUsForm bind:isOpen />

  <section class="btn-container">
    <div class="btn-container__inside">
      <PriceDisplay {price} />
      <button disabled={!price} class="btn btn--primary">Next</button>
    </div>
  </section>
</form>

<style>
  @import "../../styles/variables.css";

  .space-under {
    margin-bottom: 1rem;
  }

  .links {
    margin-top: 2em;
  }

  .change-location,
  .currency-converter,
  .course-companion-link {
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
  }

  .currency-converter button {
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
  }

  .currency-converter button::before {
    content: "> ";
  }

  .currency-converter button:hover {
    cursor: pointer;
  }

  .open {
    margin-bottom: var(--baseline);
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
    cursor: pointer;
  }
</style>
